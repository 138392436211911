export const trimLeft = (string: string, character = 's'): string => {
  return string.replace(new RegExp('^[' + character + ']+'), '');
};

export const trimRight = (string: string, character = 's'): string => {
  return string.replace(new RegExp('[' + character + ']$'), '');
};

export const trim = (string: string, character = 's'): string => {
  return trimLeft(trimRight(string, character), character);
};
