import { trimLeft } from './string';

interface DYApiObject {
  context: DYApiContext;
  url: string;
  countAsPageview: boolean;
}
interface DYApiContext {
  type?: string;
  data?: string[];
}

const pushToDynamicYield = (arg1: string, arg2: DYApiObject): void => {
  if (window.DY) {
    window.DY.API(arg1, arg2);
  }
};

export const pageview = (path: string, type?: string): void => {
  pushToDynamicYield('spa', {
    context: {
      type: type,
      data: [trimLeft(path, '/')],
    },
    url: process.env.NEXT_PUBLIC_SITE_URL + trimLeft(path, '/'),
    countAsPageview: true,
  });
};

export const setcontext = (path: string, type?: string): void => {
  pushToDynamicYield('spa', {
    context: {
      type: type,
      data: [trimLeft(path, '/')],
    },
    url: process.env.NEXT_PUBLIC_SITE_URL + trimLeft(path, '/'),
    countAsPageview: false,
  });
};

export const setDYContext = (type: string, data: string): void => {
  if (window.DY) {
    window.DY.recommendationContext = { type: type, data: data };
  }
};
