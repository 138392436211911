import { sha256 } from 'js-sha256';
import phone from 'phone';

interface DataLayerObject {
  event?: string;
  eventData?: EventData;
  pageData?: PageData;
  [key: string]: unknown;
}
interface PageData {
  path: string;
  title: string;
}
interface EventData {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}

export const pushToDataLayer = (obj: DataLayerObject, hashValues?: boolean): void => {
  window.dataLayer = window.dataLayer || [];
  if (hashValues) {
    // foreach key in obj, hash the value
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object') {
        obj[key] = JSON.stringify(obj[key]);
      }
      if (typeof obj[key] === 'string') {
        let normalized = obj[key] as string;
        // check if the key is a phone
        if (key.toLowerCase().includes('phone') || key.toLowerCase().includes('mobile')) {
          // convert to E.164 format
          const ephone = phone(normalized);
          if (ephone.isValid) {
            normalized = ephone.phoneNumber;
          }
        }
        // remove leading and trailing whitespace
        normalized = normalized.trim();
        // convert to lowercase
        normalized = normalized.toLowerCase();
        obj[key] = sha256(normalized);
      }
    });
  }
  window.dataLayer.push(obj);
};

export const pageview = (path: string, title: string): void => {
  pushToDataLayer({
    event: 'pageview',
    pageData: {
      path,
      title,
    },
  });
};

type GTagEvent = {
  action: string;
  category: string;
  label: string;
  value: number;
};
export const event = ({ action, category, label, value }: GTagEvent): void => {
  pushToDataLayer({
    event: action,
    eventData: {
      category,
      action,
      label: label,
      value: value,
    },
  });
};
