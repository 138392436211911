import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';

const UserContext = createContext<UseUser | null>(null);
UserContext.displayName = 'UserContext';

type SetPreferredLocation = (location: number) => void;
type SetLastSelectedLocation = (location: number) => void;
type SetFirstVisit = () => void;
type SetLatLng = (latitude: number, longitude: number) => void;
type SetFlag = (flag: string) => void;
type SetTheme = (theme: string) => void;

type UseUser = {
  preferredLocation?: number;
  lastSelectedLocation?: number;
  firstVisit?: number;
  latitude?: number;
  longitude?: number;
  flags?: string[];
  theme?: string;
  setPreferredLocation: SetPreferredLocation;
  setLastSelectedLocation: SetLastSelectedLocation;
  setLatLng: SetLatLng;
  setFlag: SetFlag;
  setTheme: SetTheme;
  loaded: boolean;
};

export const useUserContext = (): UseUser => useContext(UserContext) as UseUser;

type UserData = {
  preferredLocation?: {
    value: number;
    expires: number;
  };
  lastSelectedLocation?: {
    value: number;
    expires: number;
  };
  firstVisit?: {
    value: number;
    expires: number;
  };
  latitude?: {
    value: number;
    expires: number;
  };
  longitude?: {
    value: number;
    expires: number;
  };
  theme?: {
    value: string;
    expires: number;
  };
  flags?: string[];
};

export const UserProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [mounted, setMounted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState<UserData>({});

  const setUserValue = useCallback(
    <K extends keyof Omit<UserData, 'flags'> = keyof Omit<UserData, 'flags'>>(
      key: K,
      value: Required<UserData>[K]['value'],
      expires = 0,
    ): void => {
      setUser((user) => ({
        ...user,
        [key]: { value, expires: expires === 0 ? 0 : Date.now() + expires },
      }));
    },
    [],
  );

  const setFlag = (flag: string): void => {
    const flags = user.flags ?? [];
    if (!flags.includes(flag)) {
      flags.push(flag);
      setUser((user) => ({
        ...user,
        flags: flags,
      }));
    }
  };

  const getUserValue = useCallback(
    <K extends keyof Omit<UserData, 'flags'> = keyof Omit<UserData, 'flags'>>(
      key: K,
    ): Required<UserData>[K]['value'] | undefined => {
      const userProp = user[key];

      if (!userProp) return undefined;
      if (userProp.expires > 0 && userProp.expires < Date.now()) return undefined;

      return userProp.value;
    },
    [user],
  );

  const getUserFlags = (): string[] => {
    return user.flags ?? [];
  };

  const setPreferredLocation: SetPreferredLocation = (location) => {
    setUserValue('preferredLocation', location, 0);
  };

  const setLastSelectedLocation: SetLastSelectedLocation = (location) => {
    setUserValue('lastSelectedLocation', location, 0);
  };

  const setFirstVisit: SetFirstVisit = useCallback(() => {
    setUserValue('firstVisit', Date.now(), 0);
  }, [setUserValue]);

  const setLatLng: SetLatLng = (latitude, longitude) => {
    setUserValue('latitude', latitude, 15 * 60 * 1000);
    setUserValue('longitude', longitude, 15 * 60 * 1000);
  };

  const setTheme: SetTheme = useCallback(
    (theme) => {
      setUserValue('theme', theme, 0);
    },
    [setUserValue],
  );

  useEffect(() => {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme:dark)').matches;
    if (!mounted) {
      if (typeof document !== 'undefined') {
        try {
          const localUser = JSON.parse(localStorage.getItem('tg-user') || '').value as UserData;
          setUser(localUser);

          if (!localUser.firstVisit) {
            setFirstVisit();
            setTheme(prefersDarkMode ? 'dark' : '');
          }
        } catch (err) {
          setFirstVisit();
          setTheme(prefersDarkMode ? 'dark' : '');
          // do nothing
        }

        setLoaded(true);
      }

      setMounted(true);
    }
    const html = document.getElementsByTagName('html');
    html[0].classList.forEach((c) => {
      html[0].classList.remove(c);
    });
    const theme = getUserValue('theme');
    if (theme) {
      html[0].classList.add(theme);
    }
  }, [mounted, setFirstVisit, setTheme, getUserValue]);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      localStorage.setItem('tg-user', JSON.stringify({ value: user }));
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        preferredLocation: getUserValue('preferredLocation'),
        lastSelectedLocation: getUserValue('lastSelectedLocation'),
        firstVisit: getUserValue('firstVisit'),
        latitude: getUserValue('latitude'),
        longitude: getUserValue('longitude'),
        theme: getUserValue('theme'),
        flags: getUserFlags(),
        setPreferredLocation,
        setLastSelectedLocation,
        setLatLng,
        setFlag,
        setTheme,
        loaded,
      }}>
      {children}
    </UserContext.Provider>
  );
};
