import '../../styles/globals.scss';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';

import { UserProvider } from '../providers/userProvider';
import * as dy from '../utils/dynamicYield';
import * as gtag from '../utils/gtag';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  },
});

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string): void => {
      if (!url.includes('?')) {
        gtag.pageview(url, document.title);
        dy.setcontext(url, router.asPath === '/us/' ? 'HOMEPAGE' : 'OTHER');
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return (): void => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.asPath, router.events]);

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <Component {...pageProps} />
      </UserProvider>
    </QueryClientProvider>
  );
};

export default MyApp;
